@import '../vars';

.roadtripperslink {
  display: inline-block;
  line-height: 24px;
  color: $text-color;
  font-weight: bold;
  text-decoration: none;
  font-size: 1em;

  svg {
    width: 30px;
    height: 24px;
    vertical-align: bottom;
    fill: currentColor;
  }
}
