@import '../vars';

.consentdialog {
  padding: 20px;
  z-index: 102;
  max-height: 100vh;
  overflow: auto;

  p {
    margin: 10px 0;
  }

  h1 {
    margin: 0 0 10px 0;
  }
}

.consent__buttons {
  display: flex;
  gap: 20px;
  margin-top: 10px;

  .cta_button {
    padding: 10px 20px 7px;
    line-height: 1;
    flex: 1 1 auto;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    gap: 10px;
    flex-direction: column-reverse;
  }
}

.consent__level {
  border: 1px solid $brand-quinary;
  border-radius: 5px;
  padding: 10px 10px 10px 35px;
  position: relative;
  cursor: pointer;
  margin-top: 10px;

  p {
    margin: 5px 0 0 0;
  }

  &--active,
  &:hover {
    border: 1px solid $brand-secondary;
  }

  input {
    position: absolute;
    top: 15px;
    left: 8px;
  }

  label {
    font-weight: bold;
  }
}
