@import '../vars';

.message {
  border: 1px solid;
  margin-bottom: 10px;
  padding: 2px 7px;
  border-radius: 3px;
  background: $body-bg-secondary;

  &.message-success {
    border-color: $brand-secondary;
  }

  &.message-error {
    border-color: $red;
  }
}
