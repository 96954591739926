@import '../vars';

.fuelcalculator {
  max-width: 500px;
  margin: $space auto;
  display: block;
}

.fuelcalculator__sectionlabel {
  display: block;
}

.fuelcalculator__distance,
.fuelcalculator__distancetype,
.fuelcalculator__days,
.fuelcalculator__dayslabel {
  display: inline-block !important;
}

.fuelcalculator__distance {
  width: 180px;
}

.fuelcalculator__days {
  width: 80px;
}

.fuelcalculator__vehiclesection,
.fuelcalculator__destinationsection {
  position: relative;
}

.fuelcalculator__vehicle,
.fuelcalculator__destination {
  width: 100%;
}

.fuelcalculator__buttons {
  text-align: right;

  button {
    display: inline-block;
    float: none;
  }
}

.fuelcalculatorpane {
  background: $body-bg-secondary;
  color: $brand-primary;
  border-bottom: 2px solid $grey;
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 2px 10px 2px 10px;
  box-sizing: border-box;
  margin-top: -7px;
  border-radius: 3px;
}

.fuelcalculatorpane__inner {
  max-height: calc(4 * 1.5em);
  overflow-y: auto;
  overflow-x: hidden;
}

.fuelcalculatorpane__item {
  border: 0;
  background: none;
  display: block;
  padding: 0;
  height: 1.5em;

  &--haschildren::after {
    content: ' ▸';
    color: $brand-secondary;
  }

  &--back::before {
    content: '◂ ';
    color: $brand-secondary;
  }
}

.fuelcalculatorresult {
  clear: both;
  line-height: 1.1;
  margin: 20px 0 0 0;
  padding: 8px 10px;
}

.fuelcalculatorresult__disclaimer {
  margin-top: 1em;
  font-size: 95%;
  line-height: 1;
}

@media (max-width: $screen-xs) {
  .fuelcalculatorpane {
    position: fixed;
    bottom: 10px;
    left: 0;
    width: calc(100vw - 40px);
    max-height: 50vh;
    margin: 0 20px;
  }

  .fuelcalculatorpane__inner {
    max-height: 50vh;
  }
}
