@import '../vars';

.articlecontainer,
.article-search-container {
  flex-basis: 100%;
}

.articleblocks {
  &.extra-margin-top {
    margin-top: 3vw;
  }

  &.less-margin-top {
    margin-top: -4vw;
  }

  &.less-margin-bottom {
    margin-bottom: -3vw;
  }

  &.extra-margin-bottom {
    margin-bottom: 3vw;
  }

  .placeholder__container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;

    > .placeholder,
    > .component-wrapper,
    > .scEmptyPlaceholder {
      flex: 0 0 384px;
      min-width: 384px;
    }

    > .placeholder {
      display: flex; // This makes the content fill the height of the container.
    }

    @media (max-width: 384px) {
      display: block;

      > .placeholder,
      > .component-wrapper {
        min-width: 100%;
        display: block;
      }
    }
  }
}

.articleblock {
  background-color: $white;
  margin: 20px;
  border-radius: 4px;
  padding: 20px;
  flex: 0 1 100%;

  // Experience editor.
  .component-wrapper & {
    margin: 0;
  }

  @media (max-width: 384px) {
    margin: 10px 4px;
  }

  a {
    text-decoration: none;
    color: $brand-primary;
  }

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  }

  .heading {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 0;
    word-break: break-word;
  }

  .articleimg {
    height: 200px;
    margin: -10px;
    margin-bottom: 10px;
    position: relative;

    .article-scaled-image > img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

.articlecontainer {
  background-color: $body-bg-secondary;
}

.article-search-container {
  background: none;
  margin-top: 3vw;

  .articleblocks {
    .placeholder__container {
      max-width: 1255px;

      .productwidget {
        margin-left: 1.5vw;
      }
    }
  }
}

.articlecontainer__theme {
  &-white {
    background-color: $white;
  }

  &-primary {
    background-color: $brand-primary;
  }

  &-secondary {
    background-color: $brand-secondary;

    &--reversed {
      background-color: $white;

      .articleblocks {
        .articleblock {
          background-color: $brand-secondary;
          color: $white;
        }
      }
    }
  }

  &-tertiary {
    background-color: $brand-tertiary;

    &--reversed {
      background-color: $white;
      color: $white;

      .articleblocks {
        .articleblock {
          background-color: $brand-tertiary;
        }
      }
    }
  }

  &-quaternary {
    background-color: $brand-primary;

    &--reversed {
      background-color: $white;
      color: $white;

      .articleblocks {
        .articleblock {
          background-color: $brand-quaternary;
        }
      }
    }
  }

  &-senary {
    background-color: $body-bg-secondary;

    &--reversed {
      background-color: $white;

      .articleblocks {
        .articleblock {
          background-color: $body-bg-secondary;
        }
      }
    }
  }
}
