@import '../vars';

.filters {
  flex: 0 0 0;
  min-width: 209px;

  .filterstoggle {
    margin-right: -52px;
    float: right;
    background-color: $white;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    box-shadow: 7px 0 12px -1px rgba(0, 0, 0, 0.5);
    display: none;

    svg {
      vertical-align: bottom;
    }
  }

  .filtertoggle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 8px 8px 18px;
    position: relative;
    cursor: pointer;
  }

  .filtertoggle:before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center center;
    height: 38px;
    width: 18px;
    content: ' ';
    background-size: 50%;
    background-repeat: no-repeat;
    transition: 0.4s;
    transform: rotate(-90deg);
  }

  .filteropen:before {
    transform: rotate(0deg);
  }

  .noarrow:before {
    display: none;
  }

  .noarrow {
    padding-left: 5px;
  }

  .filterlist {
    background-color: $white;
    border-radius: 4px;
    max-height: 111px;
    padding: 10px;
    overflow: hidden;
    transition: 0.4s;
    .destinationselect {
      margin-bottom: 5px;
      box-shadow: none;
      border-bottom: none;
      display: block;
      text-decoration: none;
      color: $brand-primary;
    }
    input[type='checkbox'] {
      margin-right: 10px;
    }
    label {
      display: block;
    }
  }

  .minmax {
    display: flex;
    .prefix {
      display: inline;
      margin-right: 2px;
    }
    .no-margin {
      margin-right: 0;
    }
    .pricemax,
    .pricemin {
      display: inline-block;
      min-width: 40px;
    }
    .pricemax.small {
      min-width: 19px !important;
    }
    .pricemax.extra-small {
      min-width: 10px !important;
    }
    .pricemax.small-price {
      min-width: 30px !important;
    }
  }
  .minmax > div {
    flex: 1 0 0;
  }
  .minmax > div:nth-of-type(2) {
    text-align: right;
  }

  .submit {
    background-color: rgba(121, 167, 30, 1);
    color: $white;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border-bottom: 3px solid rgba(74, 103, 18, 1);
    margin-bottom: -10px;

    @media (min-width: $screen-md + 1) {
      display: none;
    }
  }

  .filtercontent {
    padding: 10px 10px 20px 10px;
    background-color: $brand-quinary;
  }

  .filtercontent--alt .searchtypes label:nth-of-type(1) {
    border-radius: 4px 4px 0 0 !important;
  }

  .filtercontent--alt .searchtypes label:nth-of-type(2) {
    border-radius: 0 0 4px 4px !important;
  }

  .filtermore {
    transition: max-height 1s;
    max-height: 1000px;
  }
  .more {
    text-align: right;
    cursor: pointer;
    margin-bottom: -10px;
  }
  .more--grey {
    color: $grey;
  }
  .dateselect,
  .destinationselect {
    background-color: $body-bg-secondary;
    padding: 4px 8px;
    border-radius: 3px;
    cursor: pointer;
    border-top: 1px solid $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  .dateselect.active,
  .destinationselect.active {
    color: $white;
    background-color: $brand-primary;
  }
  .disable-selection {
    pointer-events: none;
  }
}

@media (max-width: $screen-md) {
  .filters {
    position: fixed;
    box-shadow: 7px 0 12px -1px rgba(0, 0, 0, 0.5);
    width: 280px;
    left: 0;
    transform: translateX(-280px);
    background-color: $white;
    transition: transform 0.4s;
    z-index: 9999;
    overflow-x: visible;
    top: 140px;
    height: calc(100vh - 140px);
    box-sizing: border-box;
    padding-bottom: 30px;

    &--expanded {
      transform: translateX(0);
    }
  }

  .filtercontent {
    overflow: auto;
    height: 100%;
  }

  .filterstoggle {
    display: block !important;
    background-color: $brand-quinary !important;
    z-index: 9999999;
    cursor: pointer;
  }
}

@media (max-width: $screen-md) {
  .filters {
    top: 160px;
    height: calc(100vh - 160px);
  }
}

.searchtypes {
  text-align: center;
  margin: 10px;
  label {
    width: 50%;
    box-sizing: border-box;
    margin-left: 0;
    text-align: center;
  }
}

.range-slider {
  &__wrap {
    min-height: 14px;
    margin: 10px;
  }

  .rc-slider {
    margin: 0;
  }

  .rc-slider-rail {
    background: #fafafa;
  }

  .rc-slider-track {
    background: $brand-primary;
    height: 8px;
    top: 3px;
  }

  .rc-slider-handle {
    background: $brand-primary;
    border-color: $brand-primary;
    border-radius: 5px;
    height: 28px;
    margin-top: -12px;
    width: 15px;
    margin-left: 0;
  }
}
