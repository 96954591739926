@import '../vars';
@import '../mixins/info';

.results {
  flex: 1 1 auto;
  padding-left: 20px;
  max-width: 100%;
  box-sizing: border-box;

  .sort {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: right;

    .select-style {
      display: inline-block;
      margin-left: 5px;
      width: 160px;
    }
  }

  @media (max-width: $screen-md) {
    padding-left: 0;
  }
}

.select-style {
  width: 170px;
  border-radius: 3px;
  overflow: hidden;
  background: $body-bg-secondary no-repeat 90% 50% url(../../images/select.png);
  background-size: 10px;
  border: 0;
  border-bottom: 2px solid $grey;
  position: relative;

  select {
    padding: 3px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent none;
    -webkit-appearance: none;
    color: $brand-primary;
    font-style: italic;
    font-size: 1em;
    border: 0;
    margin: 0;
  }

  select::-ms-expand {
    display: none;
  }

  i {
    position: absolute;
    right: 5px;
    top: 2px;
  }
}

.mapcontainer {
  display: none;
  margin: 0 $space;
}

.result {
  display: flex;
  cursor: pointer;
  margin-top: $space;
  margin-bottom: $space;
  width: 100%;
  color: $white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  .result-background {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $black;

    .react-player {
      width: 120% !important;
      height: 120% !important;
      top: -25px !important;
      left: -40px !important;
    }

    iframe {
      transform: scale(2);
    }

    .react-player__shadow,
    .react-player__play-icon {
      display: none;
    }
  }

  .result-background__image {
    width: 100%;
    height: inherit;
  }

  .result-content {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
  }

  a {
    color: $white;
  }

  .info {
    width: 210px;
    font-size: 0.9em;
    background-color: $brand-quaternary;
    color: $white;
    padding: $space 15px;
    height: 190px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0 5px 5px 0;
    line-height: 1.3em;
    text-align: left;
    position: relative;

    &__description {
      max-height: 147px;
      overflow: hidden;
      position: relative;
      word-break: break-word;

      ul {
        margin: 0;
        padding: 0 0 0 20px;
      }

      @supports (-webkit-line-clamp: 8) {
        /* autoprefixer statements are for the entire block */
        /* autoprefixer: off */
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        &:after {
          /* autoprefixer: on */
          display: none;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }

  .info__icons {
    position: absolute;
    left: 15px;
    bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    height: 43px;
    width: 80px;

    .info__icons__icon {
      width: 25%;
      margin: 0 5% 5% 0;
      flex: 0 0 auto;
      height: 0;
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--many {
      padding: 0;

      .info__icons__icon {
        width: 20%;
      }
    }

    &--few {
      padding: 0 10px;
      width: 50px;

      .info__icons__icon {
        width: 40%;
      }
    }
  }

  .journeywidget,
  .blogwidget {
    overflow: hidden;
    padding: 20px;
    height: 250px;
    box-sizing: border-box;
    position: relative;
  }

  .result-label {
    background-color: #34444a;
    display: inline-block;
    float: left;
    padding: 5px;
    margin: -5px;
    text-transform: uppercase;
  }

  .cta_button {
    padding: 15px 4px 14px 4px;
    right: 15px;
    line-height: 13px;
    z-index: 3;
    bottom: 10px;
    height: 14px;
    position: absolute;
    margin: 0;
    width: 100px;
    font-size: $font-size-large;
    float: none;
    text-transform: none;

    &.cta_button--price {
      height: 26px;
      padding-top: 3px;
      width: 90px;
    }

    .pricelabel,
    .pricevalue {
      color: inherit;
      display: block;
    }

    .pricelabel {
      text-align: right;
      font-size: 11px;
    }

    .pricevalue {
      font-size: $font-size-largest;
      width: 100%;
    }
  }

  &:hover {
    .cta_button {
      background-color: $brand-tertiary;
    }
  }

  // Maybe the below three rules should be put in _toplevel.scss.
  .tipbottom {
    max-width: 100%;
    left: 20px;
  }

  .tipleft {
    max-width: 30%;
  }

  & .tipleft + .tipbottom {
    max-width: 75%;
    left: auto;
  }
}

.info--withpricedescription {
  .info__description {
    max-height: 125px;
  }

  .info__icons,
  .cta_button {
    margin-bottom: 25px;
  }
}

.result .journeywidget,
.result .blogwidget {
  flex-grow: 1;
  background-size: cover;
  background-position: center;
}

.result:nth-of-type(odd) .journeywidget,
.result:nth-of-type(odd) .blogwidget {
  order: -1;
}

.result:nth-of-type(even) .info {
  margin-left: 0;
  margin-right: 0;
  border-radius: 5px 0 0 5px;
}

.result .tipbottom * {
  text-align: right;
}

.result .tipbottom .tip {
  text-align: left;
}

.result .preheader {
  width: 100%;
  text-align: right;
}

.result--blog {
  margin: 33px 0 20px 0;

  .info__description {
    max-height: 75px;
    @supports (-webkit-line-clamp: 4) {
      -webkit-line-clamp: 4;
    }
  }

  .blog-info {
    margin-top: 33px;
    font-size: 0.95em;
  }

  .tipbottom {
    max-width: unset;
    left: 20px;
    > .tip_blog {
      text-align: left;
    }
  }

  @media (max-width: 1065px) {
    flex-direction: column;
    flex: 1 0 auto !important;
    .info {
      @include info;
    }
  }

  @media (min-width: $screen-sm) and (max-width: 1065px) {
    .journeywidget.hasvideobackground {
      height: 200px;
    }
  }
}

.contentsearch__noresults {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
}

@media (max-width: $screen-sm) {
  .result {
    flex-direction: column;
    flex: 1 0 auto !important;
  }

  .result .info {
    @include info;
  }
}

@media (max-width: $screen-xs) {
  .result {
    .result-content {
      padding: 10px;
    }

    .topleft {
      margin: 0;
    }

    .tipbottom {
      bottom: 10px;
      right: 10px;
      left: 10px;
    }

    // TODO: centralize tourleader tip css + tsx.
    .tipleft .tip {
      float: none;
      width: 70px;
      font-size: 1em;
      text-align: center !important;
      line-height: 1;

      br {
        display: none;
      }

      img {
        float: none;
        margin: 0 0 5px 0 !important;
        display: block;
      }
    }
  }
}

.result__pricedescription {
  margin: 0 15px 5px 0;
  font-size: 0.9em;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  line-height: 1;
}
