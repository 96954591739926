@import '../vars';

@mixin info {
  height: auto;
  border-radius: 0 !important;
  width: auto !important;
  order: 2;
  text-align: left !important;
  box-shadow: inset 0 -12px 10px -10px $black;
  margin: 0 0 !important;
  padding: 10px 10px 58px 10px !important;

  .tooltip {
    left: unset;
    right: 131px;
  }
}
