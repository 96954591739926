@import '../vars';

$transition: ease-in-out 300ms;

.searchform-container {
  position: relative;
  z-index: 2;

  @media (max-width: 685px) {
    margin-bottom: 100px;
  }
  @media (max-width: $screen-sm) {
    margin-bottom: 0;
  }

  .searchform {
    margin-bottom: 20px;
    background-color: #ddd;
    width: 678px;
    padding: 2px 20px;
    left: 50%;
    margin-left: -349px;
    bottom: -43px;
    position: absolute;

    // Small screens
    @media (max-width: $screen-sm) {
      bottom: 0;
      background: #f2f2f2;
      width: 100%;
      left: 0;
      padding: 0;
      position: relative;
      transition: all $transition;
      margin: 0 auto;
      transform: translateY(0);
    }
  }
}

.searchform__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    margin: 5px;
  }

  select,
  input {
    padding: 5px 8px;
    box-shadow: none;
    -webkit-appearance: none;
    color: #34444a;
    font-style: italic;
    font-size: 1em;
    margin: 0;
    max-width: 95vw;
    border-radius: 3px;
    overflow: hidden;
    background: #fff url(../../images/select.png) no-repeat 150px 50%;
    background-size: 10px;
    border: 0;
    width: 170px;
    box-sizing: border-box;
    font-family: inherit;
    cursor: pointer;
    &:focus {
      outline-color: $brand-secondary;
    }
  }
}

.searchform__row--slide {
  padding-left: 5px;
  padding-right: 5px;
  transition: all $transition;
  width: calc(100% - 10px);
}

.searchform__row--slidehidden {
  transform: translate(0, 0) scale(1, 0);
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.searchform__row--slideactive {
  transform: translate(0, 0) scale(1, 1);
  max-height: 600px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.searchform__slideitem {
  background-color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  border-top: 1px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin: 5px;
}

.searchform__slideitem:hover,
.searchform__slideitem--active {
  border-top: 1px solid #999;
  color: #fff;
  background-color: #34444a;
}

.searchform__button {
  text-decoration: none;
  color: #fff;
  background-color: #79a71e;
  padding: 5px 40px;
  margin-top: -2px;
  display: block;
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    background-color: #34444a;
    color: #fff;
  }
}

// The following styles operate on the rendering wrappers.
.rendering-travelhome-spotlight-header ~ .rendering-travelhome-search-form {
  margin-top: -10px;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 0; // This is intentional, the content will flow out of the container.

  // This causes the scroll-up effect.
  .searchform {
    position: absolute;
    bottom: 0;
  }

  // Small screens
  @media (max-width: $screen-sm) {
    margin-top: 0;
    position: relative;
    height: auto;

    .searchform {
      position: relative;
      bottom: auto;
    }
  }
}
