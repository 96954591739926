@import '../vars';

#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #f5f5f5;
  color: #444;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  padding: 0;

  .no-scrolling & {
    display: none;
  }
}

#back-to-top:hover {
  background: $grey;
}

#back-to-top.show {
  opacity: 1;
}
