/**
* Brand
*/

$brand-primary: #34444a !default;
$brand-secondary: #79a71e !default;
$brand-tertiary: #426514 !default;
$brand-quaternary: #1b2427 !default;
$brand-quinary: #dbe9f0 !default;
$text-color: $brand-primary !default;
$body-bg: #ffffff !default;
$body-bg-secondary: #f2f2f2 !default;
$black: #000000 !default;
$white: #ffffff !default;
$grey: #aaaaaa !default;
$red: #ff6a00 !default;

$space: 20px !default;

$screen-xs: 420px !default;
$screen-sm: 760px !default;
$screen-md: 1000px !default;

$font-family: 'TheSans', sans-serif !default;
$font-size-small: 12px !default;
$font-size-smaller: 13px !default;
$font-size-base: 14px !default;
$font-size-medium: 15px !default;
$font-size-large: 16px !default;
$font-size-largest: 22px !default;
$font-size-heading: 2em !default;
$font-size-heading-md: 1.5em !default;
$font-size-body: 1em !default;

$header-size: 130px !default;
$header-size-sm: 147px !default;
