@import '../vars';

.carousel {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  .carousel__image {
    height: inherit; // 405px inherited from lazy load
    position: relative;

    img {
      display: block;
      max-width: 100%;

      // Center vertically.
      margin: 0 -50% 0 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .carousel__thumbs {
    width: calc(100% - 100px);
    margin: 10px auto 0 auto;

    .slick-track {
      white-space: nowrap;
      float: left;

      > div {
        display: inline-block;
        float: none;
        width: 100px;
      }
    }

    &--center {
      .slick-track {
        text-align: center;
        width: 100% !important;
        transform: translateX(0) !important;
      }
    }

    .slick-arrow {
      background: transparent;
      top: 32px;

      &.slick-prev {
        left: -66px;
      }

      &.slick-next {
        right: -66px;
      }
    }

    div {
      outline: none;
    }

    .slick-slide {
      box-sizing: border-box;
      padding-right: 10px;
    }

    img {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #ccc;
      cursor: pointer;

      &.thumb-active {
        border: 1px solid $black;
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 32px;
  width: 48px;
  height: 48px;
  text-align: center;
  font-weight: 800;
  line-height: 48px;
  font-size: 31px;
  cursor: pointer;

  &:hover {
    background: $white;
  }

  &.slick-prev {
    left: 20px;
    right: auto;
    transform: rotate(-180deg) translateY(50%);
  }

  &.slick-next {
    right: 20px;
    left: auto;
  }
}

.carousel-edit-mode {
  text-align: center;
  div {
    display: inline-block;
    width: 500px;
    margin: 20px;

    img {
      width: 100%;
      height: 300px;
    }
  }
}

.fullwidth-carousel {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  img {
    height: 300px;
    max-width: 100vw;
    display: block;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  div {
    display: block !important;
  }

  .slick-slide {
    overflow: hidden;
  }
}
