@import '../vars';

.custom_cta.actionwidget--theme {
  // Common settings for dark variants.
  &-primary,
  &-quaternary {
    color: $white;
    border: 2px solid $brand-quaternary;
    border-bottom: 3px solid $black;

    .cta_button {
      background-color: $brand-secondary;

      &:hover {
        background-color: $brand-tertiary;
      }
    }
  }

  &-primary {
    background-color: $brand-primary !important;
  }

  &-secondary {
    background-color: $brand-secondary !important;
    color: $white;

    .cta_button {
      background-color: $brand-primary;

      &:hover {
        background-color: $brand-quaternary;
      }
    }

    &--reversed {
      background-color: $white !important;
      color: $brand-secondary;
    }
  }

  &-tertiary {
    background-color: $brand-tertiary !important;
    color: $white;

    .cta_button {
      background-color: $brand-primary;

      &:hover {
        background-color: $brand-quaternary;
      }
    }
  }

  &-quaternary {
    background-color: $brand-quaternary !important;

    &--reversed {
      background-color: $white !important;
      color: $brand-quaternary;

      .cta_button {
        background-color: $brand-quaternary;
      }
    }
  }

  &-senary {
    background-color: $body-bg-secondary !important;
    color: $text-color;

    .cta_button {
      background-color: $brand-secondary;
    }

    &--reversed {
      background-color: $white !important;
      color: $text-color;

      .cta_button {
        background-color: $brand-secondary;
      }
    }
  }
}
