@import '../vars';

.nav-trigger {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.nav-trigger-label {
  display: none;
  position: absolute;
  top: 31px;
  left: 15px;
  z-index: 2;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: contain;
}

.nav-trigger:checked + .nav-trigger-label {
  transform: rotate(90deg);
}

.nav-trigger-label--edit-mode {
  position: absolute !important;
}

.header {
  height: $header-size;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10000;

  .header-overlay {
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 5px $space rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 999;
    top: 0;
  }

  .header-overlay--fixed {
    position: fixed;
  }

  .header-overlay--small {
    .navright {
      height: 5px !important;
    }
  }

  //TODO: Implement this
  // .header-overlay_results {
  //   background-size: cover;
  //   /* background-attachment: fixed;
  //   background-position: 100% 5%;
  //   background-image: url(assets/land_europa.jpg);
  //   background-blend-mode: color-dodge;
  //   box-shadow: none;
  //   top: 0px;
  //   border-bottom: 4px solid $white;
  // }
  .header-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 90px;
    justify-content: center;
    padding-right: 20px;
  }

  .header-main-logo-container {
    width: 350px;
    padding-left: $space;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    height: 72px;
  }

  .header-main-logo {
    height: 100%;

    & > img {
      max-height: 100%;
      display: block;
    }
  }

  .header-main-logo-info {
    display: block;
    position: absolute;
    right: -40px;
    top: 15px;
    font-size: 16px;
    width: 40px;
    color: #34444a;
    line-height: 16px;
    text-align: right;
  }

  .navright {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    box-sizing: border-box;
    align-self: flex-end;
    flex-direction: row-reverse;
    overflow: hidden;

    .topleft {
      text-align: left;
      letter-spacing: 2px;
      margin: 10px 0 6px 0;

      &--clickable {
        cursor: pointer;
      }

      .topleft-reviews {
        display: block;
        text-align: center;
        letter-spacing: 0;
        font-weight: 100;
        font-size: 12px;
        color: #666;
      }

      > .item.star-rating {
        div.star-rating__item {
          margin: 0;
        }
      }
    }

    .topleft__usericon {
      letter-spacing: normal;
      display: none;
      margin: 7px 0 0 0;
      font-weight: bold;
    }

    .topleft-rating {
      color: #79a71e;
      font-size: 39px;
      line-height: 31px;
      margin-right: 20px;
      margin-top: 6px;
      & > a {
        text-decoration: none;
      }
    }
  }

  .headercontact {
    display: flex;
    width: auto;
    font-size: $font-size-body;
    position: relative;
    justify-content: flex-end;
  }

  .navcenter {
    display: none !important;
    position: absolute;
    left: 50%;
    margin-left: -120px;
  }

  .navcenter > div {
    width: 130px;
    text-align: right;
    padding-right: 35px;
    border-radius: 3px;
    overflow: hidden;
    // background: url("assets/select.png") no-repeat 90% 50%; TODO:implement this
    background-size: 10px;
    border: 0;
    display: inline-block;
    margin-right: 0;
    margin-top: 0;
    cursor: pointer;
  }

  .navcenter .submenu {
    z-index: 999;
  }

  .submenu ul {
    z-index: 99;
    height: 0;
    transition: 1s;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0 0 4px 4px;
    top: 45px;
    padding: 0 $space $space;
    overflow: hidden;
    opacity: 0;
  }

  .submenu a {
    text-decoration: none;
  }

  .headercontact .svg {
    height: 15px;
    width: 15px;
    margin: 5px 5px 5px 15px;
  }

  .navcontact {
    flex-direction: column;
    font-style: italic;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;

    a {
      text-decoration: none;
      height: 21px;
      margin-left: 15px;
    }

    a:hover {
      color: $brand-secondary;
    }
  }

  .navcontact__tooltipcontainer {
    position: relative;

    @media (max-width: $screen-md) {
      width: 95%;
    }
  }

  .navcontact__tooltip {
    top: 0;
  }

  .subnav {
    display: flex;
    justify-content: flex-start;

    a {
      color: $brand-primary;
      text-decoration: none;
      font-size: 14.8px;
      padding: 8px 10px;
    }

    a:hover {
      color: $brand-secondary;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        height: 40px;
        vertical-align: middle;
        display: table-cell;
        cursor: pointer;
      }
    }
  }

  .subnav-dropdown {
    background-color: rgba(0, 0, 0, 0.05);

    .subnav-menu {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.85);
    }

    ul {
      height: 0;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.15);
      display: block;
      padding-left: 30px;

      li {
        display: inline-block;
      }

      a {
        font-size: $font-size-smaller;
        padding: 10px 4px;
        display: inline-block;
      }
    }
  }

  .subnav-dropdown--show {
    @extend .subnav-dropdown;

    ul {
      height: auto;
      overflow: visible;
    }
  }

  .subshow {
    background-color: rgba(0, 0, 0, 0.15);
  }

  a.menusub {
    background-color: rgba(0, 0, 0, 0.2);
    color: $white;
  }

  .menusub:last-child {
    padding-right: 10px !important;
  }

  .menusub {
    display: none;
  }

  .svg path {
    fill: $brand-primary;
  }

  .headercontact a {
    text-decoration: none;
  }

  .logo-anwb {
    height: 40px;
    margin: 10px 12px 0 22px;

    > img {
      width: auto;
      height: 100%;
      display: block;
    }
  }
}

.bottomnav {
  display: flex;
  background-color: rgba(0, 0, 0, 0.05);
  align-items: center;
  padding: 0 $space;

  // This makes the subnav take all remaining space on desktop.
  .subnav {
    flex-grow: 10;
  }
}

.bottomnav__usericon .headerusericon__userlink {
  color: $text-color;

  &:hover {
    color: $brand-secondary;
  }
}

/** Search Box **/
.bottomnav__searchbar {
  .searchbox {
    margin: 0;
  }
}

@media (min-width: $screen-sm + 1) {
  .bottomnav__searchbar {
    margin-right: $space;
    position: relative;
    width: 32px;
    height: 28px;

    .searchbox {
      margin: 0;
      width: 210px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

/** Mijn Travelhome login **/
.headerusericon {
  display: flex;
}

.headerusericon__usericon {
  width: 24px;
  height: 24px;
  display: block;
  padding: 0 !important;
  margin-right: 10px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.headerusericon__userlink {
  padding: 0 !important;
  font-size: inherit !important;
  line-height: 24px;
  display: block;
  text-decoration: none;
}

.header-edit-mode {
  margin-bottom: 80px;
}

@media (max-width: $screen-md) {
  header.header {
    .logo-anwb {
      display: none;
    }

    .navright .topleft__usericon {
      display: block;
    }

    .subnav a {
      font-size: 0.75em;
      padding: 4px;
    }

    .subnav--open {
      a.main-menu-item {
        font-size: inherit;
        padding: 0;
      }
    }

    .header-main-logo-container {
      padding-left: 10px;
    }

    .navright .topleft-rating {
      margin-right: 12px;
    }
  }

  .bottomnav__usericon {
    display: none;
  }
}

@media (max-width: $screen-sm) {
  .nav-trigger-label {
    display: block;
  }

  header.header {
    height: $header-size-sm;

    ul > li {
      display: block;
    }

    .header-main {
      flex-direction: row;
      height: auto;
      padding-right: 0;
    }

    .header-main .navright {
      transition: height 300ms ease-in-out;
      height: 65px;
      justify-content: center;
      margin: 0;
      padding-right: 0;
      flex-direction: row-reverse;
      text-align: center;

      .navcontact {
        flex-direction: column;
        justify-content: flex-start;
      }

      &--low {
        height: 40px;
      }
    }

    .headercontact {
      display: none;
    }

    .mainnav {
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 5px;
      margin-right: 0;
      padding-bottom: 5px;

      a {
        font-size: 0.9em;
        padding-bottom: 2px;
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    .header-main-logo-container {
      max-width: 100%;
      padding: 5px 50px 0 50px;
      max-height: 100px;
      width: 450px;
      height: auto;
    }

    .header,
    .videobackground {
      max-height: 420px;
      height: 420px;
      min-height: 420px;
    }

    .header-main-logo-info {
      display: none;
    }

    .countryheader h1 {
      text-align: center;
      font-size: 11vw;
      line-height: 9.5vw;
      letter-spacing: 1vw;
      padding: 0;
      margin: 10px 0 0 0;
    }

    .countryheader h2,
    .countryheader p {
      text-align: center;
      font-size: 5vw;
      line-height: 5vw;
      letter-spacing: 1.2vw;
      padding: 0;
      margin: 0;
    }

    .countryheader p {
      font-size: 2vw;
      letter-spacing: 0;
      line-height: 4vw;
    }
  }
}

// Mobile subnav styling
@media (max-width: $screen-sm) {
  .header-overlay--fixed {
    .no-scrolling & {
      height: 100%;
    }
  }

  header.header {
    .bottomnav {
      flex-direction: column;
      display: none;
      position: relative;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 20px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

      overflow-y: scroll;
      overflow-x: hidden;

      .bottomnav__searchbar {
        margin-bottom: 10px;
      }

      > .subnav {
        flex-direction: column;
        order: 2;

        > ul {
          padding-left: 0;

          > li {
            width: 100vw;
            position: relative;
            border-bottom: 1px solid #34444a;

            &.has-dropdown {
              &:after {
                content: '';
                position: absolute;
                border-bottom: 2px solid #79a71e;
                width: 20px;
                right: 0;
                top: 20px;
              }

              &:before {
                content: '';
                position: absolute;
                border-left: 2px solid #79a71e;
                height: 20px;
                right: 9px;
                top: 11px;
              }
            }

            &.has-dropdown.menu-item-open {
              border-bottom-color: transparent;

              &:before {
                display: none;
              }
            }
          }
        }
      }
    }

    .subnav-dropdown {
      background-color: rgba(255, 255, 255, 0.9);
      display: none;

      &--show {
        display: block;
      }

      .subnav-menu {
        position: relative;
        background-color: inherit;
      }

      ul {
        height: auto;
        overflow: visible;
        background-color: rgba(255, 255, 255, 0.9);
        display: block;
        padding-left: 30px;

        a {
          font-size: $font-size-smaller;
          padding: 0;
        }

        li {
          display: block;
        }
      }

      ul,
      &--show ul {
        background-color: #f0f0f0;
        margin: 0 -30px;

        li {
          &:first-child {
            padding-top: 10px;
          }
        }
      }
    }

    .nav-trigger-label {
      transition: 0.2s;

      &:after {
        display: block;
        position: absolute;
        content: '';
        border-right: 6px solid #34444a;
        left: 12px;
        top: -5px;
        height: 16px;
        transform: rotate(90deg);
        transform-origin: center;
        transition: 0.2s;
      }

      &:before {
        display: block;
        position: absolute;
        content: '';
        border-right: 6px solid #34444a;
        left: 12px;
        top: -5px;
        height: 16px;
        transform: rotate(-90deg);
        transform-origin: center;
        transition: 0.2s;
      }
    }

    .nav-trigger:checked + .nav-trigger-label {
      background: none !important;
      transition: 0.2s;

      &:after {
        transform: rotate(45deg);
        height: 40px;
      }

      &:before {
        transform: rotate(-45deg);
        height: 40px;
      }
    }
  }
}

.bottomnav--open {
  height: calc(100vh - 154px);
  display: flex !important;

  .header-overlay--small & {
    height: calc(100% - 89px);
  }
}

.subnav--edit-mode {
  z-index: 3;
}

.header--alt {
  height: 140px;
  max-height: 140px;
  min-height: 140px;
}

@media (max-width: 390px) {
  .header {
    .navcontact {
      margin-right: 0;
    }

    .navright .topleft-rating {
      margin-right: 10px;
      font-size: 32px;
    }

    &.header .nav-trigger-label {
      top: 20px;
    }
  }
}

.header__versioninfo {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  pointer-events: none;
  color: rgba(255, 255, 255, 0);

  @media (max-width: $screen-md) {
    display: none;
  }
}

// Height override for phone portrait.
@media (max-width: $screen-xs) {
  header.header {
    // 0.21879 is the height of the svg logo file divided by the width.
    // 100 px is the padding added to the sides of the logo (50px + 50px).
    // 5px is the padding added to the top.
    // 40px is the height of the score + phone nrs.
    height: calc(0.21875 * (100vw - 100px) + 5px + 40px);
  }

  .bottomnav--open {
    // 100vh minus the height of header.header and the padding of 2x20px.
    height: calc(100% - 21.875vw - 88.121px);
  }
}
