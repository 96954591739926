@import '../vars';

.quote {
  margin-bottom: 20px;

  &--spacing-top {
    margin-top: 20px;
  }

  img {
    border-radius: 50%;
    border: 3px solid $white;
    margin-top: 5px;
    margin-left: 5px;
    float: left;
    height: 90px;
    margin-right: 10px;
    max-width: 90px;
  }

  .content img {
    border-radius: 0;
    height: auto;
    float: left;
    border: none;
    margin-top: 5px;
    margin-left: 0;
    width: 100%;
  }

  .scWebEditInput {
    iframe {
      width: auto; //override for fixing the experience editor
    }
  }

  .details-content {
    margin: 20px 0;
  }
}

.quote-edit-mode-text {
  color: red;
  text-align: center;
}

.quote-edit-mode {
  border: red dashed 1px;
}

.introtext-address,
.introtext-date {
  font-style: italic;
}

.introtext-camping,
.introtext-news {
  &__image {
    background: $brand-primary;
  }
}
