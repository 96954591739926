@import '../vars';

.paging {
  float: left;
  margin: 0 0 10px 20px;

  @media (max-width: $screen-xs) {
    // Creates extra space for the filter button.
    margin-left: 47px;
  }

  a {
    color: $brand-primary;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid $brand-primary;
    padding: 2px 8px 2px 8px;
    line-height: $space;
    margin: 0 4px 4px 4px;
    border-radius: 3px;
    display: inline !important;

    &:hover {
      color: $white;
      background-color: $brand-primary;
    }
  }

  .cta_button {
    margin-top: -5px;
    margin-left: 10px;
  }

  .extra-margin {
    margin-left: 38px;
  }

  > div {
    display: inline;
  }
}

.pricetable--paging {
  width: 100%;
  clear: both;
  margin: $space;
  float: left;
}
