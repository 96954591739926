@import '../vars';

body {
  font-family: $font-family;
  color: $brand-primary;
  margin: 0;
  padding: 0;

  &.no-scrolling {
    overflow: hidden;
  }
}

a {
  color: $brand-secondary;

  &:hover {
    color: $brand-tertiary;
  }
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

p {
  margin: 0;
  padding: 0;
}

.site-wrap {
  background-color: $white;
  position: relative;
  top: 0;
  bottom: 100%;
  left: 0;
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 800px;
  min-width: 100%;
}

.framecontainer.extra-margin-top,
.frame.extra-margin-top {
  margin-top: 70px;
}

.container--edit-mode {
  .component-wrapper {
    .component-content.clearfix {
      .container {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        min-height: 200px;
        max-height: 300px;
        font-size: 2em;
        background: $brand-secondary;
        text-align: center;
      }
    }
  }
}

.star {
  fill: $white;
  stroke: $white;
  stroke-width: 2;
  height: 1.5em;
  max-width: 8em;
}

.star--half {
  fill: url(#halfGradient);
}

.star--empty {
  fill: none;
}

.star-source {
  position: absolute;
  width: 0;
  top: 0;
}

.page legend,
.heading {
  font-size: $font-size-heading;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.05em;
  font-weight: bold;
  padding-left: 0;
}

.heading-normal {
  text-transform: none;
}

.heading2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}

h3,
.heading3 {
  display: block;
  font-size: 1.17em;
  font-weight: bold;
}

h4,
.heading4 {
  display: block;
  font-size: $font-size-body;
  font-weight: bold;
}

.heading_blog {
  font-size: 24px;
  text-align: left !important;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.7) !important;
}

.ul {
  padding-left: 20px;

  li {
    position: relative;
    list-style: none;

    &:before {
      content: '•';
      color: $brand-secondary !important;
      font-size: 30px;
      line-height: 0;
      display: block;
      left: -0.6em;
      position: absolute;
      top: 0.25em;
    }
  }
}

.sidenav {
  &.custom_cta {
    margin-top: 0;
  }

  a {
    cursor: pointer;
    display: block;
    text-decoration: none;
    padding: 4px;
    color: $brand-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  // This may apply to all custom_cta elements.
  @media (max-width: $screen-sm) {
    margin: 0;
  }
}

.anchor {
  display: block;
  position: relative;
  top: -135px;
  visibility: hidden;
}

ul.sidesubmenu {
  background-color: #fff;
  border-radius: 5px;
  margin: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;

  &.expand-sidesubmenu {
    transition: 0.5s;
  }
}

ul.expand-sidesubmenu {
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
}

.sidesubmenu__activelink {
  font-weight: bold;
}

.readmore__content {
  text-align: left;
  display: none;
  margin-top: 15px;
}

.readmore__triggerlabel {
  display: block;
  text-align: right;
  margin-top: 15px;
  cursor: pointer;
  color: $brand-secondary;

  &:hover {
    color: $brand-tertiary;
  }
}

.readmore__triggerlabel--hide {
  display: none;
}

.readmore__triggercheckbox {
  display: none;
}

.readmore--editmode .readmore__content,
.readmore__triggercheckbox:checked ~ .readmore__content {
  display: block;
}

.readmore__triggercheckbox:checked ~ .readmore__triggerlabel--show {
  display: none;
}

.readmore__triggercheckbox:checked ~ .readmore__triggerlabel--hide {
  display: block;
}

.page {
  margin-bottom: 20px;
  display: none;
}

.pag1 {
  display: block;
}

.margin-bottom {
  margin-bottom: 20px;
}

.subpage {
  flex-wrap: wrap;

  ul {
    list-style-type: disc;
  }
}

.text {
  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}

.edit-mode-text {
  background: white;
  color: red !important;
}

input,
button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.centered {
  margin: 0 auto;
}

.placeholder__container .related-content-block {
  border: 2px solid #fff;
  border-bottom: 3px solid #aaa;
  background-color: #79a71e !important;
  color: #fff;
  height: 100px;
  line-height: 100px;
}

.placeholder__container .related-content-block h3 {
  margin: 0;
  display: inline-block;
}

.related-content-block {
  .hamburger-icon {
    position: relative;
    display: inline-block;
    width: 1.25em;
    height: 0.8em;
    margin-right: 0.3em;
    border-top: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    &:before {
      content: '';
      position: absolute;
      top: 0.3em;
      left: 0;
      width: 100%;
      border-top: 0.2em solid #fff;
    }
  }
}

.withcopyright {
  position: relative;

  &::after {
    content: attr(data-copyright);
    color: $black;
    display: block;
    font-size: 8px;
    line-height: 1.1em;
    position: absolute;
    bottom: 2px;
    left: 2px;
    max-width: 100%;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.9);
    padding: 2px;
    border-radius: 2px;

    &:hover {
      background-color: $white;
    }
  }
}

// Quill editor alignments
$alignments: left right center justify;
@each $alignment in $alignments {
  .ql-align-#{$alignment} {
    text-align: $alignment;
  }
}

li.ql-align-left,
li.ql-align-right,
li.ql-align-center,
li.ql-align-justify {
  list-style-type: none;

  &:before {
    content: '\2022';
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }
}

// details/summary
summary {
  color: $brand-secondary;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: $brand-tertiary;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.htmlcontainer {
  ul:first-child,
  p:first-child {
    margin-top: 0;
  }

  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}

.keepnewlines {
  white-space: pre-line;
}
