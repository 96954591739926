@import '../vars';

.boxed {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-wrap: nowrap; /* this is important for the no-results page */

  &--left {
    justify-content: flex-start;
  }
}

.boxed.extra-margin-top {
  margin-top: 25px;

  @media (max-width: $screen-xs) {
    margin-top: 15px;
  }
}

.col1-2 {
  width: 50%;
  flex: 0 0 auto;
}

.col2-3 {
  flex: 2;
}

.col1-3 {
  width: 33%;
  flex: 1;

  .placeholder__container[data-placeholder-length='1'] {
    height: 100%;

    .placeholder {
      height: 100%;
    }
  }
}

@media (min-width: $screen-sm + 1) {
  .col1-2,
  .col2-3,
  .col1-3 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}

.col-full {
  width: 100%;
}

.col-half {
  width: 100%;

  @media (min-width: $screen-sm + 1) {
    width: 50%;
    // Make sure the column takes up the same space when the container is shrunk below 1200px wide.
    min-width: 600px;
  }
}

@media (max-width: $screen-sm) {
  .boxed {
    flex-direction: column;
    flex: 1 0 0;
    margin: 0;
    padding: 10px;
  }

  .col1-2 {
    flex: 1 0 auto !important;
    width: 100%;
  }

  .col2-3 {
    flex: 2 0 auto !important;
  }

  .col1-3 {
    flex: 1 0 auto !important;
    width: 100%;
  }

  .col1-3:nth-child(2),
  .col1-2:nth-child(2) {
    order: -1;

    .boxed--firstcolumnfirst & {
      order: 1;
    }
  }
}

@media (min-width: $screen-sm + 1) {
  .colupper {
    margin-top: -270px;
  }
}
