@import '../vars';

.searchbox {
  width: calc(100% - 12px); // minus border and padding.
  min-width: 20px;
  height: 26px;
  padding: 0 5px;
  margin: 5px 10px 8px 0;
  border-radius: 30px;
  border: 1px solid $brand-primary;
  position: relative;
  background: #fff;
}

.searchbox--collapsible {
  will-change: width;
  transition: width 200ms ease-in-out, font-size 200ms ease-in-out, margin-top 200ms ease-in-out;
}

.searchbox--collapsed {
  width: 20px !important;
  border-color: transparent;
  background: transparent;

  .searchbox__label,
  .searchbox__field {
    display: none;
  }

  .searchbox__button {
    font-size: 1.2em;
    margin-top: -0.1em;
  }
}

.searchbox--open {
}

.searchbox__field {
  outline: none;
  -webkit-appearance: none;
  height: $space;
  padding: 0 0 0 5px;
  border: 0;
  background: none;
  font-size: $font-size-body;
  font-style: italic;
  margin-top: 2px;
  display: block;
  float: left;
  width: calc(100% - 25px);
  position: relative;
}

.searchbox__button {
  position: absolute;
  text-decoration: none;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  right: 4px;
}
