@import '../vars';

footer {
  background-color: $brand-primary;
  position: relative;

  .footerrow {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    .footercol {
      flex: 1;
      color: $white;
      padding: 20px;
      flex-basis: auto;
      height: auto;

      .heading {
        font-size: 1.2em;
        color: $white;
      }

      p {
        color: $body-bg-secondary;
      }

      strong {
        color: $white;
      }

      a {
        display: block;
        color: $white;
        text-decoration: none;
        + .heading {
          margin-top: 20px;
        }
      }

      .footer-text a {
        display: inline;
      }

      a:hover {
        text-decoration: underline;
      }

      > .footer-social-icons > a {
        display: inline-block;
        text-decoration: none;
        &:before {
          font-size: 23px;
        }
      }
    }
    .footercol--highlight {
      background-color: $brand-quaternary;
      margin-top: -10px;
      img {
        height: 24px;
      }
    }
    .footercol--highlight_color {
      @extend .footercol--highlight;

      &__theme-primary {
        margin-top: -10px;
        background-color: $brand-primary;
      }

      &__theme-secondary {
        margin-top: -10px;
        background-color: $brand-secondary;
        &--reversed {
          background-color: $white;
          > .heading,
          a,
          h2 {
            color: $brand-secondary !important;
          }
        }
      }

      &__theme-tertiary {
        margin-top: -10px;
        background-color: $brand-tertiary;
      }

      &__theme-quaternary {
        margin-top: -10px;
        background-color: $brand-quaternary;
        .footer-social-icons a {
          color: $white !important;
        }

        &--reversed {
          background-color: $white;
          > .heading,
          a,
          h2 {
            color: $brand-quaternary !important;
          }
        }
      }
    }

    &.footer-logos {
      justify-content: center;
      padding-top: 20px;
      margin-bottom: -10px;
      flex-wrap: wrap;
      flex-direction: row;

      img {
        height: 30px;
        margin: 5px 10px;
        display: block;

        @media (max-width: $screen-sm) {
          margin: 5px;
        }
      }
    }
  }

  .footercontainer {
    &__theme-white {
      background-color: $white;
      color: $text-color;
    }

    &__theme-primary {
      background-color: $brand-primary;
    }

    &__theme-secondary {
      background-color: $brand-secondary;
      &--reversed {
        background-color: $white;
        .footercol {
          > .heading,
          a,
          h2 {
            color: $brand-secondary !important;
          }
        }
      }
    }

    &__theme-tertiary {
      background-color: $brand-tertiary;
    }

    &__theme-quaternary {
      background-color: $brand-quaternary;

      &--reversed {
        background-color: $white;
        .footercol {
          > .heading,
          a,
          h2 {
            color: $brand-quaternary !important;
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm) {
    .footercol--highlight {
      order: -1;
    }

    .footerrow {
      flex-direction: column;

      .footercol a + .heading {
        margin-top: 40px;
      }
    }
  }
}
