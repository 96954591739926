@import '../vars';

.camperinformation {
  background-color: $body-bg-secondary;
  padding: 10px;
  margin: 20px 0;
  border-radius: 10px;

  .carousel {
    margin-bottom: 0;
  }
}

.camperinformation__definitionlist {
  $leftcolsize: 175px;
  display: flex;
  max-width: 500px;
  flex-wrap: wrap;
  margin-bottom: $space;

  dt {
    flex: 0 0 auto;
    width: $leftcolsize;
    font-weight: bold;
  }

  dd {
    margin: 0;
    flex: 0 1 auto;
    width: calc(100% - $leftcolsize);
  }
}

.camperinformation__tooltip {
  max-width: 90vw;
  width: 400px;
}

.camperinformation__content {
  margin-bottom: $space;
}

.camperinformation__virtualtour {
  width: 100%;
  border: 0;
  height: 0;
  padding-top: 75%;
  position: relative;
  margin-bottom: $space;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.camperinformation__beds {
  text-align: right;
  margin-bottom: $space;

  td,
  th {
    padding: 1px 10px;
  }
}

.camperinformation__videoheader {
  h3 {
    display: inline-block;
  }

  .camperinformation__videopager {
    display: inline-block;
    float: none;
  }
}

.camperinformation__video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  .react-player__preview {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.camperinformation__stars {
  display: inline-block !important;
  margin: 8px 0 -8px 5px;
}
