@import '../vars';

@import '../vars';

.tooltip__button {
  margin-left: 10px;
  display: inline-block;
  position: relative;
  height: 1em;
}

.tooltip__icon {
  position: absolute;
  vertical-align: bottom;
  z-index: 4;
  width: 20px;
  height: 20px;
  overflow: hidden;
  color: #999;
  padding: 0;
  border: 0;
  cursor: pointer;

  &.iconsmall {
    width: 16px;
    height: 16px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.tooltip__tooltipinbutton {
  left: -20px;
  font-weight: normal;
  border-radius: 8px !important;

  &:before {
    right: auto;
    left: 20px;
  }
}

.tooltip__tooltip {
  background: $brand-secondary;
  color: #fff;
  position: absolute;
  padding: 10px;
  border-radius: 8px;
  width: 250px;
  top: 32px;
  z-index: 5;

  &:before {
    content: ' ';
    position: absolute;
    right: 10px;
    top: -19px; // Prevent gap for weird zoom levels.
    width: 0;
    height: 0;
    border: transparent 10px solid;
    border-bottom: 10px $brand-secondary solid;
    pointer-events: none;
  }

  @media (max-width: $screen-xs) {
    right: 0 !important;
    border-top-right-radius: 0;

    &:before {
      right: 0;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &--right {
    right: 10px;
  }
}
