@import '../vars';

.custom_cta {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  background-color: $body-bg-secondary;
  padding: 5px $space $space $space;
  border-radius: 4px;
  border: 2px solid $white;
  border-bottom: 3px solid $grey;
  margin: $space 0;
  box-shadow: -1px 5px 15px rgba(0, 0, 0, 0.3);

  .heading {
    text-transform: uppercase;
    font-size: $font-size-heading;
    margin-top: 10px;

    @media (max-width: $screen-md) {
      font-size: $font-size-heading-md;
    }
  }

  .subheadingem {
    font-style: italic;
    margin: 0;
  }

  @media (max-width: $screen-xs) {
    padding: 5px 10px 10px 10px;
  }
}

.custom_cta.blog {
  border-color: $black !important;

  .heading,
  .subheading {
    text-transform: uppercase;
  }

  .heading {
    text-transform: uppercase;
    font-size: 2em;
    margin: 10px 0 0 0;
  }

  .subheading {
    margin: 0;
    font-size: 1.4em;
  }
}

.cta_button {
  text-decoration: none;
  background-color: $brand-secondary;
  padding: 5px 40px 2px 40px;
  margin-top: $space;
  display: block;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  border-bottom: 3px solid $brand-tertiary;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  float: right;
  cursor: pointer;

  &,
  &:hover {
    color: $white;
  }

  &:hover {
    background-color: $brand-tertiary;
  }

  &[disabled] {
    cursor: default;
    color: $body-bg-secondary;

    &:hover {
      background-color: $brand-secondary;
    }
  }
}

.cta_button--secondary {
  color: $brand-primary;
  background: $brand-quinary;
  border-bottom-color: $brand-quaternary;

  &:hover {
    color: $white !important;
    background: $grey;
    border-bottom-color: $brand-primary;
  }
}

.cta_button--tertiary {
  color: $white !important;
  background: $brand-primary;
  border-bottom-color: $brand-primary;

  &:hover {
    background: $brand-quaternary;
    border-bottom-color: $brand-quaternary;
  }
}

.cta_button--small {
  line-height: 21px;
  font-size: 0.9em;
  padding: 4px 30px 1px 30px;
}
