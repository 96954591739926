@use 'sass:math';

@import '../vars';

.spotlight-header {
  position: relative;
  height: 460px;
  margin-top: $header-size * -1;
  padding-top: $header-size;
  padding-bottom: 1px; // prevent margin collapsing

  @media (min-width: $screen-xs + 1) and (max-width: $screen-sm) {
    margin-top: $header-size-sm * -1;
    padding-top: $header-size-sm;
    height: auto;

    .spotlight-header-wrapper.countryarea &,
    .spotlight-header-wrapper.map &,
    .spotlight-header-wrapper.trip & {
      height: 40vh;
      min-height: 300px;
      max-height: 460px;
    }
  }

  @media (max-width: $screen-xs) {
    margin-top: 0;
    padding-top: 10px;

    .spotlight-header-wrapper.countryarea &,
    .spotlight-header-wrapper.map &,
    .spotlight-header-wrapper.trip & {
      // 11 px is from the top and bottom padding.
      // 62.5 is 10/16.
      height: calc(62.5vw - 11px);
    }
  }

  .spotlight-header-wrapper.customerarea & {
    height: auto;
  }

  .react-player {
    &--video-loaded {
      opacity: 0;
    }

    &--video-started {
      opacity: 1;
    }
  }

  .productwidget {
    position: relative;
    z-index: 2;
    margin: 50px 20px 20px 20px;

    * {
      text-align: left;
    }
  }

  .awards {
    position: absolute;
    top: 140px;
    opacity: 0.8;
    right: 10px;
    z-index: 2;

    @media (max-width: $screen-sm) {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      display: block;
    }

    li {
      display: inline-block;
      margin: 0 10px;
    }

    img,
    svg {
      vertical-align: bottom;
      display: inline-block;
      width: 80px;
      height: auto;
    }
  }

  .tip {
    float: left;
    position: relative;

    @media (max-width: $screen-xs) {
      max-width: 160px;
    }

    @media (max-width: $screen-sm) {
      float: none;
    }

    &__title {
      position: relative;
      top: 18px;
    }

    img {
      float: left;
      margin-left: 0 !important;
      margin-right: 5px !important;
    }
  }

  &__media-background {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
    overflow: hidden;
    background-color: $black;

    .react-player {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      iframe {
        transform: scale(2.5);
      }
    }
  }

  &__media-image {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;

    img {
      width: 100%;
      object-fit: cover;
      display: block;
      height: 100%;
    }
  }

  .headercountryinner {
    margin: 20px;
    position: relative;
    z-index: 2;
    color: $white;

    @media (max-width: $screen-xs) {
      &--withtourleaders {
        margin: 0;
        bottom: 5px;
        position: absolute;
        width: 100%;
      }
    }

    h1 {
      font-size: 70px;
      line-height: 70px;
      letter-spacing: 0.5vw;
      text-align: center;
      padding: 0;
      margin: 30px 0 0 0;
      @media (max-width: 900px) {
        font-size: 8vw;
        line-height: 1;
      }
    }

    h2 {
      font-size: 48px;
      line-height: 42px;
      text-align: center;
      letter-spacing: 0.5vw;
      padding: 0;
      margin: 0;

      @media (max-width: 900px) {
        font-size: 5.5vw;
        line-height: 1;
      }
    }

    .headercountryinner__text {
      font-size: 28px;
      line-height: 77px;

      @media (max-width: $screen-sm) {
        font-size: 20px;
        line-height: normal;
      }

      letter-spacing: 0;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }

  .countryguides {
    display: flex;
    align-content: center;
    width: 320px;
    margin: 0 auto;
  }

  .countryguide {
    transition: 0.4s;
    flex: 1;
    text-align: center;

    .land_gids:hover {
      transform: Scale(1.4);
    }

    .countryguide__name {
      @extend .heading3;
      margin: 4px;

      @media (max-width: $screen-xs) {
        margin: 0;
      }
    }

    .countryguide__role {
      font-size: 12px !important;
      line-height: 14px !important;
    }

    img {
      float: none;
      margin: 0 auto !important;
      display: block;
      border-radius: 50%;
      height: 70px;
      width: 70px;
      border: 3px solid $white;

      @media (max-width: $screen-xs) {
        width: 50px;
        height: 50px;
      }
    }
  }

  .roadtripperslink {
    position: absolute;
    display: block;
    z-index: 2;
    bottom: 5px;
    left: 120px;
  }
}

.spotlight-header-wrapper {
  position: relative;

  // Don't use `z-index: -1` because it breaks the experience editor.

  // exception for the trip variant, so the price is displayed below the spotlight-header content
  &.trip {
    @media (max-width: 1200px) {
      .spotlight-header {
        .headercountryinner {
          // leave some room for the awards
          margin-top: 10vw;

          h1 {
            font-size: 5vw;
            line-height: 4.5vw;
            margin-bottom: 1vw;
          }

          h2 {
            font-size: 3.5vw;
            line-height: 3vw;
          }
        }
      }
    }

    @media (max-width: $screen-sm) {
      position: static;
    }

    @media (max-width: $screen-xs) {
      .spotlight-header {
        .headercountryinner {
          // leave some room for the awards
          margin-top: 10vw;

          h1 {
            font-size: 24px;
            line-height: 22px;
            margin-bottom: 10px;
          }

          h2 {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .tripwidget-container {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin: 0 auto;
    z-index: 2;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 90px;
    max-width: 1200px;

    .unique-buying-reasons {
      ul {
        list-style: none;
        li:after {
          content: '\e804';
          font-family: fontello;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          width: 1em;
          line-height: 1em;
          margin-left: 0.2em;
          margin-right: 0;
        }
      }
    }

    .trip-info {
      float: right;
      margin-bottom: 0.83em;

      .trip-themes {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        .trip-themes-image {
          display: inline-block;
        }

        img {
          vertical-align: bottom;
        }
      }

      .price {
        display: inline-block;
        vertical-align: bottom;
        letter-spacing: normal;
        font-size: 24px;
        font-weight: bold;
      }

      .price__pricedisclaimer {
        margin-top: -8px;
        font-size: 0.9em;
      }

      img {
        height: 25px;
        padding: 5px;
      }
    }
  }

  @media (max-width: $screen-sm) {
    .tripwidget-container {
      bottom: auto;
      position: relative;
      margin: 20px;
    }

    .trip-info {
      background: $brand-secondary;
      padding: 15px 10px 10px;
      text-align: center;

      .price {
        margin: 0;
      }
    }

    .unique-buying-reasons {
      color: $brand-primary;
      border-right: 10px solid #79a620;
      position: relative;
      padding-right: 10px;
      margin: 0 0 20px;
      font-weight: normal;
      font-size: 16px;

      ul {
        li:after {
          content: '✔';
          font-family: $font-family;
          display: inline;
          text-decoration: inherit;
          text-align: center;
          line-height: normal;
        }
      }
    }
  }
}

.spotlight-header-edit-mode {
  margin-top: 0;
}

.map-marker {
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;

  .map-marker--image {
    width: 100%;
    position: static;
    height: auto;
  }
}

.map-content-marker {
  width: 113px;
  background-color: $brand-secondary;
  position: absolute;
  cursor: pointer;
  border-radius: 6px;
  color: white;
  padding: 5px 10px;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;

  .map-content-marker__title,
  .map-content-marker__subtitle {
    margin: 0;
  }

  .map-content-marker__title {
    font-size: 1.5em;
    font-weight: bold;
  }

  .map-content-marker__subtitle {
    font-size: 1.2em;
  }

  @media (max-width: $screen-sm) {
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 0.8em;
    width: auto;
  }
}

.info-window {
  &__title {
    text-transform: uppercase;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
  }

  &__list {
    margin: 5px 0 0;
    padding: 0;
  }
}

.spotlight-header-wrapper.buttons {
  .spotlight-buttons_container {
    position: relative;
    z-index: 2;
    margin: 20px;
    .spotlight-buttons_title {
      .heading {
        text-align: center;
        font-size: 40px;
        color: $white;
        cursor: pointer;
        font-weight: 100;
        margin-top: 80px;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        line-height: 34px;
        text-transform: none;

        @media (max-width: $screen-sm) {
          font-size: 30px;
          margin-top: 20px;
        }
      }
    }
    .spotlight-buttons {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      @media (max-width: $screen-xs) {
        flex-direction: column;
      }

      .cta_button {
        margin-left: 40px;
        padding: 15px 20px;
        font-size: 20px;
        text-transform: none;
        font-weight: 100;
        @media (max-width: $screen-sm) {
          margin: 10px 10px 0 10px !important;
        }
      }

      .cta_button:nth-child(2) {
        margin-left: 40px;
      }

      .cta_button-theme {
        &-primary {
          background-color: rgb(102, 102, 102);
          border-bottom-color: $brand-primary;
          &:hover {
            background-color: $brand-quaternary;
            border-bottom-color: $brand-quaternary;
          }
        }
        &-secondary {
          background-color: $brand-secondary;
          &:hover {
            background-color: #426514;
          }
          &--reversed {
            background-color: $white;
            color: $brand-secondary;
            &:hover {
              background-color: $brand-secondary;
              color: $white;
            }
          }
        }

        &-tertiary {
          background-color: $brand-tertiary;
          &:hover {
            background-color: $brand-secondary;
            border-bottom-color: $brand-secondary;
          }
        }

        &-quaternary {
          background-color: $brand-quaternary;
          border-bottom-color: $brand-quaternary;
          &:hover {
            background-color: $brand-secondary;
            border-bottom-color: $brand-secondary;
          }
          &--reversed {
            background-color: $brand-secondary;
            &:hover {
              background-color: $brand-quaternary;
              border-bottom-color: $brand-quaternary;
            }
          }
        }
        &-senary {
          background-color: $body-bg-secondary;
          border-bottom-color: $brand-quaternary;
          color: $text-color;
          &:hover {
            background-color: $brand-quaternary;
            color: $body-bg-secondary;
          }
          &--reversed {
            background-color: $brand-quaternary;
            border-bottom-color: $brand-quaternary;
            &:hover {
              background-color: $body-bg-secondary;
              border-bottom-color: $body-bg-secondary;
              color: $text-color;
            }
          }
        }
      }
    }
  }
}

.spotlightheadercustomerarea {
  z-index: 3;
  position: relative;

  &__column {
    padding: 0 20px 20px 20px;
    margin: 10px;
    border-radius: 6px;
    background: #dbe9f0dd;
    box-shadow: 0 5px 10px #000000aa;
    box-sizing: border-box;
    order: 1;

    @media (max-width: $screen-sm) {
      margin: 0 0 10px 0;
    }

    .heading {
      margin: $space 0;
      @media (max-width: $screen-sm) {
        margin: math.div($space, 2) 0;
      }
    }

    .boxed {
      padding: 0;
      margin: 0;

      @media (max-width: $screen-sm) {
        flex-direction: row;
      }

      @media (max-width: $screen-sm) {
        .col1-2 {
          order: 1;
        }
      }

      .col1-2:first-child {
        padding-left: 0;
        width: 40%;
      }

      .col1-2:last-child {
        padding-right: 0;
        padding-left: 0;
        width: 60%;
      }
    }
  }
}

.spotlightheadercustomerarea__trips {
  margin: 0;
  padding: 0;
  list-style: none;
}
