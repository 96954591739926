.score__title,
.score__score {
  display: inline-block;
}

.score__title {
  width: 14em;
  max-width: calc(100% - 75px);
}

.score__score {
  width: 75px;
  text-align: right;
}

.score__value {
  font-weight: bold;
  font-size: 1.2em;
}
