@import '../vars';

.edit-blog-form {
  max-width: 800px;
  margin: 0 auto;

  .edit-blog-form-intro {
    margin-bottom: 100px;

    @media (max-width: $screen-sm) {
      margin-bottom: 10px;
    }
  }

  .blog-info {
    margin-bottom: 20px;

    .heading,
    .subheading {
      text-transform: uppercase;
    }

    .heading {
      text-transform: uppercase;
      font-size: 2em;
      margin: 10px 0 0 0;
    }

    .subheading {
      margin: 0;
      font-size: 1.4em;
    }

    .subheadingem {
      font-style: italic;
      margin: 0;
    }
  }

  .cta_button {
    margin: 20px;

    @media (max-width: $screen-sm) {
      margin: 0 5px 5px 0;
      float: none;
      display: inline-block;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .blog-loginform,
  .blog-editform {
    display: flex;
    margin-bottom: 1em;
    width: 100%;

    .form-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      @media (max-width: $screen-sm) {
        flex-direction: column;
      }
    }

    .brochureform-brochureimage,
    .brochureform-brochuretitle {
      display: block;
      line-height: 1.1;
    }

    .brochureform-brochureimage img {
      vertical-align: bottom;
    }

    input:checked ~ .brochureform-brochure {
      .brochureform-brochureimage {
        opacity: 0.6;
      }
    }

    ul {
      margin-top: 0.2em;
    }
  }

  .blog-editform {
    flex-direction: column;

    @media (min-width: $screen-sm + 1) {
      .form-item {
        label {
          min-width: 20%;
        }

        .form-item-value {
          width: 80%;

          .form-item-value {
            width: auto;
          }

          label {
            min-width: 0;
          }
        }
      }
    }

    .image-fields {
      margin: 25px 0;

      .form-item {
        width: auto;
        margin: 10px 0;
      }
    }
  }

  .blog-loginform {
    flex-direction: row;
    .form-item {
      .form-item-value {
        width: 60%;

        @media (max-width: $screen-sm) {
          width: 100%;
        }
      }
    }
  }

  .form-item--componentText {
    margin-bottom: 25px;
  }
}

.edit-blog-table {
  margin-top: 100px;
  h2 {
    margin: 0;
    padding-left: 20px;
  }

  @media (max-width: $screen-sm) {
    margin-top: 20px;

    h2 {
      padding-left: 0;
    }

    .boxed {
      padding: 0;
    }
  }
}

.richtexteditor {
  .ql-container {
    font-family: inherit;
    height: 200px;
  }

  .ql-toolbar {
    padding-left: 0;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-bottom: 1px solid #aaa;
  }

  .ql-editor {
    padding: 5px;
  }
}

.reactions textarea,
.richtexteditor .ql-container {
  padding: 5px;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  color: #34444a;
  -webkit-appearance: none;
  font-size: 1em;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-bottom: 2px solid #aaa;
  display: block;
  margin: 0;
  box-sizing: border-box;
}

.reactions {
  position: relative;
  min-height: 120px;
  padding: 20px;
  background-color: #eee;
  border-radius: 10px;
  margin-right: 50px;

  .reaction {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.3);
  }

  .add_reaction {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px 20px 75px 20px;
    border-radius: 5px;
    box-shadow: inset 0 3px 5px -2px rgba(0, 0, 0, 0.3);
    margin-left: 20%;

    input[type='text'] {
      width: 100%;
      font-style: unset;
    }

    textarea {
      height: 100px;
    }
  }
}

.blog-edit-mode {
  margin-top: 100px;
}

.blogautosave {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 3px;

  .cta_button {
    float: none;
    display: inline-block;
    margin: 10px 10px 0 0;
  }
}

.blogcontent p {
  margin: 0.75em 0;
}
