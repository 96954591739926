@import '../vars';

.productwidget {
  position: relative;
  margin-bottom: 20px;

  .cta_button {
    position: absolute;
    right: 20px;
    left: 20px;
    bottom: 20px;
    padding: 5px 40px;
    @media (max-width: $screen-xs) {
      padding: 5px 25px;
    }
  }

  .react-player,
  .react-player__preview {
    position: absolute;
    width: 120% !important;
    height: 120% !important;
    top: -25% !important;
    left: -40px !important;
  }

  .react-player__shadow,
  .react-player__play-icon {
    display: none;
  }

  .tailored-content {
    position: absolute;
  }

  .text--bottom {
    bottom: 20px;
  }

  &.productwidget--withbutton .text--bottom {
    bottom: 65px;
  }

  .text--left {
    left: 20px;
  }

  .text--right {
    right: 20px;
  }

  .text--center {
    width: calc(100% - 40px);
  }
}

// TODO: check if this can be just .productwidget.highlight or just .highlight.
.productwidget:not(.customwidget).highlight {
  * {
    text-align: right;
  }

  .rating {
    text-align: left;
    font-size: 24px;
  }

  div.highlight-image > img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.customwidget {
  background-size: cover;
  overflow: auto;
  height: 210px;
  padding: 20px;
  background-position: center;
  border-left: 10px solid;
  box-shadow: inset 4px 0 0 $white, inset -300px 0 200px rgba(53, 68, 73, 0.45);
}

.camperwidget {
  background-size: cover;
  padding: 20px;
  background-position: center;
  border-left: 10px solid;
  box-shadow: inset 4px 0 0 $white, inset -300px 0 200px rgba(53, 68, 73, 0.45);
  height: 230px;
}

.productwidget--theme {
  &-primary {
    border-left-color: $brand-primary;

    .cta_button {
      background-color: $brand-primary;
      border-bottom-color: $brand-primary;

      &:hover {
        background-color: $brand-quaternary;
        border-bottom-color: $brand-quaternary;
      }
    }
  }

  &-secondary {
    border-left-color: $brand-secondary;

    .cta_button {
      background-color: $brand-secondary;

      &:hover {
        background-color: #426514;
      }
    }
  }

  &-tertiary {
    border-left-color: $brand-tertiary;

    .cta_button {
      background-color: $brand-tertiary;

      &:hover {
        background-color: $brand-secondary;
        border-bottom-color: $brand-secondary;
      }
    }
  }

  &-quaternary {
    border-left-color: $brand-quaternary;

    .cta_button {
      background-color: $brand-quaternary;
      border-bottom-color: $brand-quaternary;

      &:hover {
        background-color: $brand-secondary;
        border-bottom-color: $brand-secondary;
      }
    }
  }
}

.camperwidget img,
.customwidget img,
.blogwidget img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
}

.productwidget *,
.blogwidget * {
  color: $white;
  margin: 0;
  line-height: 1.2em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.productwidget .preheader,
.blogwidget .preheader {
  font-style: italic;
}

.camperwidget * {
  text-align: left;
}

.topleft {
  text-align: left;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 6px 0;
}

.blogpost .topleft,
.reiservaring .topleft {
  background-color: $brand-primary;
  display: inline-block;
  padding: 5px;
  margin: -5px;
}

.tip {
  width: 180px;
  overflow: visible;
  font-size: 1.2em;
  display: block;
  float: right;
}

.tip {
  div > img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    float: right;
    border: 3px solid $white;
    margin-top: 5px !important;
    margin-left: 5px !important;
  }
}

.tipprice {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  color: $white;
  font-size: 16px;
  margin: 20px;
}

.hasvideobackground .tip {
  float: left;
  text-align: left !important;
}

.hasvideobackground .tip img {
  float: left;
  margin-right: 5px;
  margin-left: 0 !important;
}

.tipbottom {
  max-width: 75%;
  position: absolute;
  right: 20px;
  bottom: 20px;

  .heading {
    font-size: 1.7em;
  }

  @media (max-width: $screen-md) {
    .heading {
      font-size: 1.5em;
    }
  }
}

.tipright,
.hovertext {
  transition: opacity ease-in-out 0.2s;
}

.tipright {
  .hashovertext:hover & {
    opacity: 0;
  }
}

.hovertext {
  font-size: $font-size-largest;
  opacity: 0;

  .hashovertext:hover & {
    opacity: 1;
  }
}

.blogwidget .tipbottom {
  z-index: 1;
}

.tipleft {
  left: 20px;
  text-align: left;
  right: auto;
  line-height: 1.3em;
  max-width: 30%;
}

.col1-3 .tipleft {
  display: none;
}

.maatrechts,
.reisfotos.maatrechts {
  display: none;
}

.productwidget.hasvideobackground,
.blogwidget {
  overflow: hidden;
  padding: $space;
  height: 250px;
  box-sizing: border-box;
  position: relative;
}

.productwidget.hasvideobackground .videobackground {
  height: 270px;
  margin: -20px;
  background-color: $brand-primary;
}

@media (max-width: $screen-md) {
  .tipleft {
    bottom: 120px;
    left: 10px;
  }
}

@media (max-width: $screen-sm) {
  .productwidget {
    margin: 10px 3px;
  }
}

.highlight {
  // Make sure the title doesn't overflow with the tourleader tip.
  // The 40px refers to the paddings of the highlight.
  // The 10px gives some margin.
  .tipright {
    width: calc(100% - 180px - 40px - 10px);
  }

  .tipleft {
    width: 180px;
  }

  @media (max-width: $screen-md) {
    padding: 10px !important;

    .videobackground {
      margin: -10px;
    }

    .tip {
      font-size: 1em;
      width: 120px;

      br {
        display: none;
      }

      div > img {
        width: 50px;
        height: 50px;
        border-width: 2px;
      }
    }

    .tipbottom {
      bottom: 10px;
    }

    .tipleft {
      bottom: 140px;
    }

    .tipprice {
      margin: 10px;
    }

    .topleft {
      margin: 0;
    }

    .tipright {
      width: auto;
    }
  }

  @media (max-width: $screen-xs) {
    .tipbottom .heading {
      font-size: 1.1em;
    }
  }
}
