@import '../vars';

// FIXME: rename #reis-van-dag-tot-dag to .travelpartnavigation.
#reis-van-dag-tot-dag {
  margin: 20px 0;

  .illustrated a {
    @extend .heading3;
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: $brand-primary;

    &:hover {
      color: $brand-secondary;
    }
  }

  .illustrated--active a {
    color: $brand-secondary;
  }

  a.edit-blog {
    color: $brand-secondary;
  }

  .illustrated a:after {
    background-image: url(../../images/select.png);
    position: absolute;
    right: -20px;
    bottom: -7px;
    background-position: center center;
    height: 38px;
    width: 18px;
    content: ' ';
    background-size: 50%;
    background-repeat: no-repeat;
    transition: 0.4s;
    transform: rotate(-90deg);
  }

  &.travelpartnavigation--extramargin {
    margin: $space;
  }

  .roadtripperslink {
    margin-top: $space;
  }
}

.travelpartcontent {
  margin: 20px;

  .illustrated div,
  .illustrated a {
    display: block;
  }

  .illustrated h3 {
    cursor: pointer;
    display: inline;
    position: relative;
    font-size: $font-size-heading !important;
  }

  .paging {
    float: none;
    margin-left: 0;

    a {
      user-select: none;
      cursor: pointer;
    }
  }

  .carousel {
    margin-top: 20px;
  }

  .day-content {
    margin: 20px 0;
  }
}

.subkaart {
  height: 100%;
}

a.fancybox {
  width: 100%;
}

.illustrated a img {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}

.pricetablesection {
  clear: both;

  @media (max-width: 650px) {
    overflow: hidden;
  }

  .form-item-value.select-style {
    width: auto;
    float: left;
    margin-left: 0;
    padding-right: 30px;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    select {
      width: 130%;
      cursor: pointer;

      &:focus {
        outline-color: $brand-secondary;
      }
    }
  }
}

.pricetablesection__pricedisclaimer {
  margin: 1em 0;
}

.price-quotation-request {
  display: flex;
  margin: $space 0;

  .price-quotation-request__text {
    padding-right: 10px;
  }

  .cta_button {
    width: 100px;
    align-self: center;
    margin-top: 0;
    flex-shrink: 0;
  }

  &.no-margin-top {
    margin-top: 0;
  }

  @media (max-width: $screen-md) {
    flex-direction: column;

    .price-quotation-request__text {
      padding-right: 0;
    }

    .cta_button {
      margin: 20px auto 0 auto;
    }
  }
}

.pricetable {
  border-collapse: collapse;
  width: 100%;

  @media (min-width: $screen-sm) and (max-width: 1150px) {
    font-size: 14px;
  }

  td {
    font-size: $font-size-medium;
    padding: 3px;
    text-align: center;

    a.cta_button {
      width: 87px;
      margin: 0;
      padding: 8px 0 5px 0;
      display: inline-block;
      float: none;
      line-height: 1;

      @media (max-width: 615px) {
        width: 77px;
      }
    }
  }

  .pricetable__pricetext {
    white-space: nowrap;
    font-size: 0.85em;
  }

  .pricetable__trip {
    text-align: left;
  }

  .pricetable__empty {
    line-height: 31px; // Height of a cta_button.
  }

  th {
    background-color: $brand-primary;
    color: $white;
    padding: 5px;
    border: 0 solid $white;
    border-top: 10px solid $white;
    white-space: nowrap;
  }

  .pricetable__arrow {
    min-width: 3vw;
    max-width: 3vw;

    @media (max-width: 405px) {
      min-width: 10vw;
      max-width: 10vw;
    }

    @media (min-width: $screen-sm) {
      min-width: 2vw;
      max-width: 2vw;
    }

    .pricetable__button {
      @media (max-width: 405px) {
        left: -16px;
      }

      @media (min-width: 406px) {
        left: -11px;
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: 1150px) {
    &.pricetable__date {
      min-width: 9vw;
      max-width: 9vw;
    }
  }

  @media (min-width: $screen-sm) {
    &.pricetable__trip {
      min-width: 10vw;
      max-width: 10vw;
    }
  }

  @media (max-width: $screen-sm) {
    &.pricetable__date {
      min-width: 15vw;
      max-width: 15vw;
    }

    &.pricetable__trip {
      max-width: 23vw;
      min-width: 23vw;
    }
  }

  .pricetable__button {
    @media (max-width: 405px) {
      left: 16px;
    }

    @media (min-width: 406px) {
      left: 11px;
    }
  }

  tr {
    &:first-child {
      height: 47px;
    }

    &:nth-child(odd) td {
      background-color: $body-bg-secondary;
    }

    @media (max-width: $screen-sm) {
      &:not(:first-child) {
        height: 7vw;
      }
    }
  }

  .pricetable__button {
    display: inline;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 0;
    position: relative;
    top: -1px;

    &:focus {
      outline-color: $brand-secondary;
    }
  }

  .pricetable__hidden {
    visibility: hidden;
  }

  @media (max-width: 450px) {
    th.pricetable__date {
      font-size: 14px;
    }

    tr {
      &:not(:first-child) {
        height: 13vw;
      }

      td {
        padding: 5px 0;

        a.cta_button {
          padding: 5px 2px 2px;
          width: 55px;
        }
      }

      td:first-child {
        font-size: 14px;
        width: 75px;
      }
    }

    .pricetable__pricetext {
      white-space: normal;
      font-size: 0.6em;
      display: block;
    }
  }
}

.fadeIn-appear {
  opacity: 0.01;

  &.fadeIn-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}

.travelpartnavigation__extratext {
  margin: 10px 0;
}

.travelpartnavigation__contenttitle {
  margin: 10px 0;
  text-transform: uppercase;
  font-size: 1.2em;
}
