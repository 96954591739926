@import '../vars';

.boxed_table {
  //IE fix;
  display: block;
  padding-left: 0;
  padding-right: 0;

  .table {
    display: flex;
    flex-wrap: wrap;
  }

  .tablerow {
    display: flex;
    flex-basis: 100%;

    .tablecell {
      flex: 1;
      display: flex;
      font-size: 0.75em;
      padding: 2px 6px;

      background-color: $body-bg-secondary;
      color: $brand-primary;
    }
  }

  .tablehead {
    display: none;

    &:first-of-type {
      display: flex;
    }

    .tablecell {
      font-size: 1em;

      color: $white;
    }
  }

  @media (max-width: $screen-sm) {
    label[for='nav-trigger'] {
      display: block;
    }

    .tablerow {
      flex-direction: column;
      flex-basis: 69%;
      margin-bottom: 10px;
      flex-grow: 1;

      .tablecell {
        flex: 1;
        line-height: 1em;
        padding: 6px 4px 2px;
        min-height: 16px;
      }
    }

    .tablehead {
      display: flex;
      flex-basis: 30%;
      max-width: 40%;

      .tablecell {
        font-size: 0.75em;
      }
    }
  }

  .table {
    &.theme-senary {
      .tablehead .tablecell {
        background-color: $grey;
      }
    }

    &.theme-primary {
      .tablehead .tablecell {
        background-color: $brand-primary;
      }
    }

    &.theme-secondary {
      .tablehead .tablecell {
        background-color: $brand-secondary;
      }
    }

    &.theme-tertiary {
      .tablehead .tablecell {
        background-color: $brand-tertiary;
      }
    }

    &.theme-quaternary {
      .tablehead .tablecell {
        background-color: $brand-quaternary;
      }
    }
  }
}
