@font-face {
  font-family: 'TheSans';
  src: url('../../../assets/fonts/anwbthesans/hinted-TheSansC4s-SemiBoldItalic.eot') format('embedded-opentype'),
    url('../../../assets/fonts/anwbthesans/hinted-TheSansC4s-SemiBoldItalic.woff') format('woff'),
    url('../../../assets/fonts/anwbthesans/hinted-TheSansC4s-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans';
  src: url('../../../assets/fonts/anwbthesans/hinted-ANWBTheSans-Bold.eot') format('embedded-opentype'),
    url('../../../assets/fonts/anwbthesans/hinted-ANWBTheSans-Bold.woff') format('woff'),
    url('../../../assets/fonts/anwbthesans/hinted-ANWBTheSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans';
  src: url('../../../assets/fonts/anwbthesans/hinted-TheSansPlain.eot') format('embedded-opentype'),
    url('../../../assets/fonts/anwbthesans/hinted-TheSansPlain.woff') format('woff'),
    url('../../../assets/fonts/anwbthesans/hinted-TheSansPlain.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
