@import '../vars';

.regionblock-container {
  margin-bottom: 20px;
}

.regionblock-row-container {
  overflow: hidden;

  a {
    text-decoration: none;
  }

  .placeholder__container {
    display: flex;
    width: 100%;

    @media (max-width: 600px) {
      display: block;
    }
  }

  // sitecore editor fix
  .component-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;

    .component-content {
      width: 100%;
    }
  }
  // sitecore editor fix
  .component-wrapper.TravelhomeRegioblokTeaser {
    flex-basis: unset;
    width: 100%;
  }

  .placeholder__container[data-placeholder-length='4'] {
    @media (min-width: 601px) and (max-width: 800px) {
      .placeholder {
        width: 50%;
      }

      & {
        flex-wrap: wrap;
      }
    }
  }

  .placeholder {
    display: block;
    width: 100%;

    .country {
      overflow: hidden;
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      position: relative;
      background: rgba(0, 0, 0, 0.2);
      background-size: cover;
      justify-content: center;
      cursor: pointer;
      border: 3px solid $white;
      box-sizing: border-box;
      transition: 0.4s;
      margin: 0;
      padding: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      &:before {
        background-size: cover;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      &.no-image__theme {
        &-primary {
          background-color: $brand-primary;
        }

        &-secondary {
          background-color: $brand-secondary;
        }
        &-secondary--reversed {
          .heading {
            color: $brand-secondary;
          }

          &:hover {
            background-color: $white;

            .country-hover {
              color: $brand-tertiary;
            }
          }
        }

        &-tertiary {
          background-color: $brand-tertiary;

          &:hover {
            background-color: $white;

            .country-hover {
              color: $brand-tertiary;
            }
          }
        }

        &-tertiary--reversed {
          background-color: $white;

          .heading {
            color: $brand-tertiary;
          }

          &:hover {
            background-color: $brand-tertiary;

            .country-hover {
              color: $brand-quaternary;
            }
          }
        }

        &-quaternary {
          background-color: $brand-quaternary;
          color: $white;
        }
        &-quaternary--reversed {
          background-color: $brand-tertiary;

          .heading {
            color: $white;
          }

          &:hover {
            background-color: $brand-quaternary;

            .country-hover {
              color: $white;
            }
          }
        }

        &-senary {
          background-color: $body-bg-secondary;

          .heading {
            color: $brand-tertiary;
          }

          &:hover {
            .country-hover {
              color: $brand-tertiary;
            }
          }
        }
        &-senary--reversed {
          background-color: $brand-secondary;

          .heading {
            color: $body-bg-secondary;
          }

          &:hover {
            background-color: $body-bg-secondary;

            .country-hover {
              color: $brand-secondary;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      .heading {
        color: $white;
        font-size: 1.6em;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        padding: 0 5px;
      }
    }

    .country-teaser {
      background-color: rgb(121, 167, 30);
      margin: 3px;
      padding: 10px;
      font-weight: bold;
      font-size: 31px;
      text-align: center;
      border-bottom: 3px solid rgb(66, 101, 20);
      cursor: pointer;
      overflow: hidden;

      &:hover {
        background-color: $brand-tertiary;
      }

      > a {
        color: rgb(255, 255, 255);

        .heading {
          font-size: 31px;
          text-transform: uppercase;
        }
      }
    }

    .country-teaser__theme {
      &-white {
        background-color: $white;
      }

      &-primary {
        background-color: $brand-primary;
      }

      &-secondary {
        background-color: $brand-secondary;

        &--reversed {
          background-color: $white;

          .heading {
            color: $brand-secondary;
          }

          &:hover {
            background-color: $white;

            .heading {
              color: $brand-tertiary;
            }
          }
        }
      }

      &-tertiary {
        background-color: $brand-tertiary;

        &:hover {
          background-color: $white;

          .heading {
            color: $brand-tertiary;
          }
        }

        &--reversed {
          background-color: $white;

          .heading {
            color: $brand-tertiary;
          }

          &:hover {
            background-color: $brand-tertiary;

            .heading {
              color: $white;
            }
          }
        }
      }

      &-quaternary {
        background-color: $brand-quaternary;
        color: $white;

        &--reversed {
          background-color: $brand-tertiary;

          .heading {
            color: $white;
          }

          &:hover {
            background-color: $brand-quaternary;

            .heading {
              color: $white;
            }
          }
        }
      }

      &-senary {
        background-color: $body-bg-secondary;

        .heading {
          color: $brand-tertiary;
        }

        &:hover {
          .heading {
            color: $body-bg-secondary;
          }
        }

        &--reversed {
          background-color: $brand-secondary;

          .heading {
            color: $body-bg-secondary;
          }

          &:hover {
            background-color: $body-bg-secondary;

            .heading {
              color: $brand-secondary;
            }
          }
        }
      }
    }

    .country-hover {
      display: none;
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      color: $white;
      box-sizing: border-box;
      margin: 10px;
      text-align: center;
      left: 0;
      top: 0;
      overflow: hidden;
    }

    .country:hover {
      .country-hover {
        display: block;
      }

      .heading {
        opacity: 0;
      }
    }

    .country--small {
      flex-basis: 33.3%;
      max-width: 33.3%;
      height: 120px;
    }

    .country--small .heading {
      font-size: 1.3em;
      letter-spacing: 0.1em;
    }
  }
}
