.react-player {
  opacity: 0;

  &--video-loaded {
    opacity: 1;
  }

  &--preview {
    opacity: 1;
  }
}

.videobackground {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 60vh;
  min-height: 550px;
  overflow: hidden;
  z-index: -1;
  video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.videobackground_land {
  height: 80vh;
  box-shadow: none !important;
}
.videobackground_reis {
  .awards {
    display: none;
    top: 50vh;
    left: 10px;
    width: auto;
    text-align: left;
  }
  .awards img {
    height: 70px;
    width: auto;
  }
}
.videobackground_resultaten {
  height: 136px;
  min-height: 136px;
  box-shadow: none;
}
