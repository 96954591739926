@import '../vars';
@import '../mixins/clearfix';

.star-rating {
  @include clearfix;
  margin-left: -4px;

  &__item {
    float: left;
    margin-left: 4px;
    font-size: 16px;

    &--full {
      fill: #fff;
      &_tertiary {
        fill: $brand-secondary;
        stroke: $brand-secondary;
      }
    }

    &--half {
      position: relative;
    }

    &--empty {
      fill: transparent;
      stroke: #fff;
      &_tertiary {
        fill: transparent;
        stroke: $brand-secondary;
      }
    }
  }

  .slim {
    margin-left: 0;
  }

  &__half-full {
    fill: #fff;

    &_tertiary {
      fill: $brand-secondary;
    }

    &__container {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50%;
      overflow: hidden;
    }
  }

  &__half-empty {
    fill: transparent;
    stroke: #fff;
    &_tertiary {
      fill: transparent;
      stroke: $brand-secondary;
    }
  }
}
