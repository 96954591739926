@import '../vars';

.pagemenu {
  justify-content: center;
  display: flex;
  box-shadow: inset 0 -5px 7px -4px rgba(0, 0, 0, 0.5), inset 0 -30px 0 $brand-primary, inset 0 -30px 15px $black;
  margin-top: -14px;
  position: relative;
  z-index: 2;
  align-items: flex-end;
}

.pagemenu-align-left {
  justify-content: flex-start;
}

a.pagemenuitem:link,
a.pagemenuitem:hover,
a.pagemenuitem:visited,
a.pagemenuitem:active {
  text-decoration: none;
  margin: 5px 5px 0 5px;
  display: block;
  padding: 5px 15px;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0 -2px 6px -3px $black;
  background-color: $brand-secondary;
  color: $white;
  border-top: 2px solid $brand-secondary;
  text-align: center;
}

a.pagemenuitem--active:link,
a.pagemenuitem--active:hover,
a.pagemenuitem--active:visited,
a.pagemenuitem--active:active {
  border: 0;
  color: $black;
  background-color: $white;
  box-shadow: -4px 0 5px -5px $black, 4px 0 5px -5px $black, 0 -2px 5px -2px $black;
}

a.pagemenuitem--nobackground {
  background: none !important;
  border: none !important;
  margin-top: 7px !important;
  box-shadow: none !important;
}

a.pagemenuitem--nobackground:hover {
  color: $brand-secondary;
}

.col1-2 .pagemenu {
  box-shadow: inset -10px 0 7px -5px $white, inset 10px 0 7px -5px $white, inset 0 -8px 9px -9px $black;
  margin-top: 0;
  margin-bottom: 20px;

  .nobackground {
    background: none !important;
    border: none !important;
    margin-top: 7px !important;
    color: $brand-primary !important;
  }
}

// 899px because of lidl iframe.
@media (max-width: $screen-sm) {
  .pagemenu {
    flex-direction: column;
    margin-top: 0;
    padding: 10px;
    box-shadow: none;
    background: $brand-quaternary;
    z-index: 1;
    align-items: normal;

    a.pagemenuitem {
      border-radius: 5px;
    }
  }
}

// Wide page menu's: collapse at 1000px (area/country pages).
@media (min-width: $screen-sm + 1) and (max-width: $screen-md) {
  .pagemenu--wide {
    flex-direction: column;
    margin-top: 0;
    padding: 10px;
    box-shadow: none;
    background: $brand-quaternary;
    z-index: 1;
    align-items: normal;

    a.pagemenuitem {
      border-radius: 5px;
    }
  }
}
