@import '../vars';

.base-screen-loader {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);

  &__inner {
    background: $white;
    max-width: 80%;
    box-shadow: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
  }
}

.full-screen-loader {
  @extend .base-screen-loader;
  position: fixed;

  &__inner {
    @extend .base-screen-loader__inner;
    padding: 90px;
  }
}

.partial-screen-loader {
  @extend .base-screen-loader;
  position: absolute;

  &__inner {
    @extend .base-screen-loader__inner;
    padding: 45px;
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.ball-pulse {
  > div {
    background-color: #000;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    display: inline-block;

    &:nth-child(1) {
      animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    &:nth-child(2) {
      animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    &:nth-child(3) {
      animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
  }
}
