@import '../vars';

.customerreview {
  height: 500px;
  position: relative;
  overflow: hidden;
  margin: 0 !important;
  box-shadow: inset 0 11px 10px -10px rgba(0, 0, 0, 0.8), inset 0 -11px 10px -10px rgba(0, 0, 0, 0.8);
  .reviewcontainer {
    height: 100%;
  }

  .react-player {
    iframe {
      transform: scale(2.5);
    }
  }

  .reviewcontent {
    position: absolute;
    top: 50%;
    color: $white;
    height: 50px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    width: 300px;
    left: 50%;
    margin-left: -200px;
    border-right: 4px solid $white;
    font-size: 1.4em;
    line-height: 1.2em;
    text-align: right;
    font-style: italic;
    padding-right: 10px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  }

  .reviewimage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .reviewgrade {
    position: absolute;
    top: 50%;
    color: $white;
    height: 50px;
    margin-top: -34px;
    display: flex;
    align-items: center;
    width: 300px;
    left: 50%;
    margin-left: 125px;
    font-size: 50px;
    text-align: left;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  }

  .partnerlogos {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 60px;
    left: 0;
    filter: brightness(0) invert(1);
    text-align: center;

    img {
      max-height: 60px;
    }
  }
}

@media (max-width: $screen-xs) {
  .customerreview {
    height: 400px;
  }
  .reviewcontainer {
    transform: scale(0.7);
  }
}
