@import '../vars';

input[type='text'],
input[type='email'],
input[type='date'],
input[type='password'],
input[type='tel'],
input[type='number'],
select,
textarea {
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
  color: $brand-primary;
  font-family: inherit;
  -webkit-appearance: none;
  font-size: 1em;
  font-style: italic;
  background: $body-bg-secondary;
  border: 1px solid $body-bg-secondary;
  border-bottom: 2px solid $grey;
  display: block;
  margin: 0 7px 7px 0;
  box-sizing: border-box;

  .form-item & {
    width: 100%;

    &[size] {
      width: auto;
    }
  }

  .form-item-error & {
    border-bottom: 2px solid $red;
    margin-bottom: 2px;
  }
}

select.form-dropdown {
  background: $body-bg-secondary url(../../images/select.png) no-repeat 98% 50%;
}

.form-error {
  display: block;
  font-size: $font-size-small;
  background-color: $white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid $red;
  margin-bottom: 3px;
}

.form-item {
  clear: both;

  label.required::after {
    content: '*';
  }
}

.brochureform-brochures {
  // Wrapper of selectable brochures and downloadable brochures.
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  flex-wrap: wrap;

  // Wrapper of picture + text.
  .brochureform-brochure {
    max-width: 145px;
    margin-right: 10px;

    @media (max-width: $screen-xs) {
      margin-right: 6px;
    }
  }

  // Wrapper of all selectable brochures
  .form-item--brochures .form-item-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: $screen-xs) {
      margin-right: 6px;
    }
  }

  // Wrapper of a checkbox and the label (picture + text).
  .form-subitem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 10px;

    @media (max-width: $screen-xs) {
      margin-right: 6px;
    }

    // No margin for the brochure itself when wrapped in a form field.
    .brochureform-brochure {
      margin-right: 0;
    }
  }

  .brochureform-brochureimage,
  .brochureform-brochuretitle {
    display: block;
    line-height: 1.1;
  }

  .brochureform-brochuretitle {
    max-width: 118px;
  }

  .brochureform-brochureimage img {
    vertical-align: bottom;
    max-height: 180px;
  }

  input {
    margin: 0 5px 0 0;
    display: block;
  }

  input:checked ~ .brochureform-brochure {
    .brochureform-brochureimage {
      opacity: 0.6;
    }
  }

  ul {
    @extend .ul;
    margin-top: 0.2em;
  }
}

form {
  .newsletter-terms {
    margin-top: 30px;
  }
}

.uploadbutton {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  .cta_button {
    margin: 0 10px 0 0;
    float: none;
    display: inline-block;

    @media (max-width: $screen-xs) {
      margin: 0 0 5px 0;
      padding: 3px 10px 1px 10px;
      font-size: 0.9em;
      display: block;
    }
  }

  label {
    font-size: 0.9em;
    display: block;

    @media (max-width: $screen-xs) {
      font-size: 0.75em;
    }
  }

  input[type='file'] {
    display: none;
  }
}

.uploadbutton__file {
  width: 100px;
  height: 100px;
  background: $body-bg-secondary;
  margin: 0 20px 0 0;
}
