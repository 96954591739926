@import '../vars';

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;

  width: 600px;
  max-width: 90vw;

  padding: 10px;
  z-index: 101;
  background: #fff;

  .cta_button {
    float: none;
  }
}

body.haspopup {
  overflow: hidden;

  &::before {
    content: ' ';
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
  }
}
