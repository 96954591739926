@import '../vars';

.minimenu {
  text-align: center;
  margin: 10px;
}

.minimenu__item {
  display: inline-block;
  margin: -2px;
  padding: 2px 10px 4px 10px;
  background-color: $brand-primary;
  color: $white !important;
  margin-top: 4px;
  font-size: 13px;
  text-decoration: none;
  // Position: relative is needed to make z-index work.
  position: relative;
  z-index: 1;

  &:hover {
    background-color: $brand-secondary;
  }

  &:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.minimenu__item--active {
  background-color: $brand-secondary;
  border: 2px solid $brand-secondary;
  border-radius: 4px;
  cursor: default;
  z-index: 2;
}
