@use 'sass:math';

@import '../mixins/scaleimage';
@import '../vars';

.rich-content-block {
  $image-small: 8.5em;
  $image-medium: 16em;
  $image-large: 23em;
  $ratio: math.div(9, 16);

  .clear {
    clear: both;
    display: block;
  }

  .content-block-container {
    display: flex;
  }

  .rich-content-block {
    .content-block-container {
      .rich-content-block__col1--full-width {
        width: 100%;
      }
    }
  }

  @media (max-width: $screen-sm) {
    &.rich-content-block {
      .content-block-container {
        flex-direction: column;
        flex: 1 0;

        .rich-content-block__col1 {
          width: 100%;
        }

        .rich-content-block__col2 {
          width: 100%;
        }

        img {
          width: auto;
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .rich-content-block__image {
    display: inline-block;
  }

  &--image-size-small {
    .rich-content-block__col1 {
      width: calc(100% / 6 * 5);
    }

    .rich-content-block__col2 {
      width: calc(100% / 6 * 1);
      text-align: center;
    }

    .rich-content-block__image {
      @include scaleImage($image-small, $ratio);
    }
  }

  &--image-size-medium {
    .rich-content-block__col1 {
      width: calc(100% / 3 * 2);
    }

    .rich-content-block__col2 {
      width: calc(100% / 3 * 1);
      text-align: center;
    }

    .rich-content-block__image {
      @include scaleImage($image-medium, $ratio);
    }
  }

  &--image-size-large {
    .rich-content-block__col1,
    .rich-content-block__col2 {
      width: 50%;
      text-align: center;
    }

    .rich-content-block__image {
      @include scaleImage($image-large, $ratio);
    }
  }

  &--alignment-right {
    .rich-content-block__col1 {
      order: 2;
    }

    .rich-content-block__col2 {
      order: 1;
      padding: 0 15px 10px 0;
    }
  }

  &--alignment-left {
    .rich-content-block__col1 {
      padding: 0 15px 19px 0;
    }
  }

  img {
    width: 100%;
  }

  &--theme-theme-primary {
    background: $brand-primary;

    * {
      color: #fff;
    }
  }

  &--theme-theme-secondary {
    background: $brand-secondary;

    * {
      color: #fff;
    }

    .cta_button {
      background-color: $brand-primary;

      &:hover {
        background-color: $brand-quaternary;
      }
    }
  }

  &--theme-theme-tertiary {
    background: $brand-tertiary;

    * {
      color: #fff;
    }

    .cta_button {
      background-color: $brand-primary;

      &:hover {
        background-color: $brand-quaternary;
      }
    }
  }

  &--theme-theme-quaternary {
    background: $brand-quaternary;

    * {
      color: #fff;
    }
  }

  &--theme-theme-quinary {
    background: $brand-quinary;

    * {
      color: #fff;
    }
  }

  &--theme-theme-senary {
    background: $body-bg-secondary;
  }
}
